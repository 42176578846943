@import "styles/variables.scss";

.logo {
  -webkit-text-stroke: 1px var(--logo-stroke);
  text-shadow: 0px 0px 10px var(--logo-shadow);
  padding: 0.25em 0.1em 0;

  transition: -webkit-text-stroke $transition, text-shadow $transition,
    color $transition;

  @media (max-width: 500px) {
    font-size: 4em;
  }

  @media (max-width: 400px) {
    font-size: 3.5em;
  }
}
