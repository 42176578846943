.header {
  padding: 0.5em 1em;
  h5 {
    font-size: 2.5em;
  }
}

.imageUpload {
  display: flex;
  justify-content: space-evenly;
}

.inProgressWrapper {
  position: relative;

  svg.deleteIcon {
    display: none;
    position: absolute;
    right: 5px;
    top: 35px;
    width: 20px;
    height: 20px;
    color: red;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
  }

  &:hover {
    svg.deleteIcon {
      display: initial;

      &:hover {
        background-color: red;
        color: white;
      }
    }
  }
}
