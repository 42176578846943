@import "styles/variables.scss";

.mask {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  z-index: 0;
  cursor: pointer;

  button {
    position: fixed;
    bottom: $lh;
    right: $lh;
    cursor: pointer;
  }
}

.content {
  position: fixed;
  width: fit-content;
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100vh;
    object-fit: cover;
  }
}
