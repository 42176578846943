@import "./reset.scss";
@import "./variables.scss";

body {
  transition: background-color $transition;
  background-color: var(--background);
}

p,
span,
div,
li {
  font-family: $font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $logoFont;
  font-size: 5em;
  text-align: center;
  font-weight: 800;
  transition: color $transition;
  color: var(--logo-main);
}
