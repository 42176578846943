$font: "Roboto", sans-serif;
$logoFont: "The Nautigal", cursive;
$transition: 0.5s;
$lh: 10px;

@mixin spinner($timingFunction: ease, $duration: 2s) {
  animation-name: spin;
  animation-duration: $duration;
  animation-iteration-count: infinite;
  animation-timing-function: $timingFunction;

  @keyframes spin {
    100% {
      transform: rotateZ(360deg);
    }
  }
}
