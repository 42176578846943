.window {
  width: 100%;
  min-width: 300px;
  max-width: 1200px;
  overflow: hidden;

  img {
    position: relative;
    bottom: 20%;
  }
}
