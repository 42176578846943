@import "styles/variables.scss";

.gallery {
  & > svg {
    position: fixed;
    right: 10px;
    top: 10px;
    font-size: 3em;
    transition: color 0.5s;
    color: var(--logo-main);
    cursor: pointer;
    z-index: 100;
    background-color: white;
    border-radius: 50%;
    padding: 5px;

    @media (max-width: 500px) {
      top: initial;
      bottom: 10px;
    }
  }
}

.cardWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;

  margin-top: 2em;

  & > div > .card {
    cursor: pointer;
    width: 250px;
    margin: 0 $lh $lh 0;

    &.dual {
      width: 250px;
    }

    .images {
      display: flex;
      margin: 0 $lh;
      // flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        cursor: pointer;
        max-width: 200px;
        margin: $lh auto;
        max-height: 150px;
        object-fit: scale-down;

        // &:not(:first-of-type) {
        //   margin-left: $lh/2;
        // }
      }
    }

    .header {
      position: relative;

      svg {
        position: absolute;
        right: $lh;
        font-size: 1.2em;
      }
    }

    .body {
      padding-left: $lh;
      padding-right: $lh;
    }

    .buttonGroup {
      width: 100%;

      button {
        font-size: 1.2em;
      }
    }
  }
}

.loading {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    visibility: hidden;
    position: absolute;
  }

  svg {
    transition: color $transition;
    color: var(--loading-icon);
    margin-bottom: 0.5em;
    margin-top: -10em;
  }

  p {
    transition: color $transition;
    color: var(--loading-text);
  }
}
